import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

import { getParamObjectModifier } from 'Util/utility';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { removeFalsyValuesFromObject } from 'Util/utilities';

const url = '/api/v1/internal-service/partner';

export const ManagePartnerList = createApi({
  reducerPath: 'ManagePartnerList',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetPocList'],
  endpoints: (builder) => ({
    getPartnerList: builder.query({
      query: (request = {}) => {
        const modifiedParam = getParamObjectModifier(request);
        return {
          url,
          method: 'GET',
          body: modifiedParam,
        };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
    getAllPoc: builder.query({
      query: ({ partnerId, ...rest }) => ({
        url: `${url}/${partnerId}/poc/list`,
        method: 'GET',
        body: removeFalsyValuesFromObject(rest),
      }),
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetPocList'],
    }),
    invitePoc: builder.mutation({
      query: ({ partnerId, payload }) => {
        return { url: `${url}/${partnerId}/poc/invite`, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetPocList']),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    updatePocDetails: builder.mutation({
      query: ({ pocId, ...rest }) => {
        return { url: `${url}/poc/${pocId}/update`, method: 'PATCH', body: rest };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetPocList']),
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const getPartnerOption = (partner) => ({
  label: partner.partner_name,
  value: partner.partner_id,
});

export const getPartnerOptions = (list = []) => {
  if (list?.length) {
    return list?.map((item) => getPartnerOption(item));
  } else return [];
};

export const {
  useLazyGetPartnerListQuery,
  useGetPartnerListQuery,
  useGetAllPocQuery,
  useUpdatePocDetailsMutation,
  useInvitePocMutation,
  usePrefetch,
} = ManagePartnerList;
export default ManagePartnerList.reducer;
