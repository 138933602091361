import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier } from '../../../Util/utility';

const getBatchListURL = '/api/v1/internal-service/batch';

export const GetBatchListApi = createApi({
  reducerPath: 'GetBatchListApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    batchListApi: builder.query({
      query: (data) => {
        const modifiedParam = getParamObjectModifier(data);

        return { url: getBatchListURL, method: 'GET', body: modifiedParam };
      },
    }),
  }),
});

const getBatchOption = (batch) => ({
  label: batch.batch_code,
  value: batch.batch_id,
});

export const getBatchOptions = (list = []) => {
  if (list?.length) {
    return list?.map((item) => getBatchOption(item));
  } else return [];
};

export const { useBatchListApiQuery, useLazyBatchListApiQuery, usePrefetch } = GetBatchListApi;

export default GetBatchListApi.reducer;
