import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

const url = '/api/v1/internal-service/recommendation-templates';

export const RecommendationTemplatesApi = createApi({
  reducerPath: 'RecommendationTemplatesApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getTemplatesList: builder.query({
      query: () => {
        return { url, method: 'GET' };
      },
    }),
  }),
});

export const { useGetTemplatesListQuery } = RecommendationTemplatesApi;

export default RecommendationTemplatesApi.reducer;
