import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const UpdatePocDetails = createApi({
  reducerPath: 'updatePocDetails',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['POC'],
  endpoints: (builder) => ({
    savePocDetails: builder.mutation({
      query: (data) => {
        const hcId = data?.host_company_id;
        const pocId = data?.pocId;
        delete data?.host_company_id;
        delete data?.pocId;
        return {
          url: `api/v1/internal-service/host-company/${hcId}/poc/${pocId}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['POC'],
    }),
    invitePocDetails: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/internal-service/host-company/${data?.host_company_id}/poc/invite`,
          method: 'POST',
          body: data?.list,
        };
      },
    }),
  }),
});

export const { useSavePocDetailsMutation, useInvitePocDetailsMutation } = UpdatePocDetails;
