import { DateTime } from 'luxon';
import { convertToTimeZoneAndFormat } from 'Util/utility';

interface FormattedDateUtilResult {
  formattedDate: string;
  error: string | null;
}

const formatTimestamp = (
  timestamp: number | string,
  fromIso: boolean = false,
  format: string = 'dd LLL, yyyy',
): FormattedDateUtilResult => {
  try {
    if (fromIso) {
      const formatted = DateTime.fromISO(timestamp).toFormat(format);
      return { formattedDate: formatted, error: null };
    }
    const dateTime = DateTime.fromSeconds(timestamp);
    const formatted = dateTime.toFormat(format);
    return { formattedDate: formatted, error: null };
  } catch (error) {
    return { formattedDate: '', error: error.message };
  }
};

export default formatTimestamp;

export const checkTheValueIsValid = (value) => {
  if (value !== null && value !== undefined && value !== '') {
    return true;
  }
  return false;
};

export const removeFalsyValuesFromObject = (obj, excludedValues = []) => {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (!excludedValues.includes(key) && !newObj[key]) {
      delete newObj[key];
    }
  }
  return newObj;
};

export const daysDifferenceFromToday = (timeStamp: number, isPastDate = false) => {
  const oneDay = 24 * 60 * 60 * 1000;

  const now = new Date();
  const startOfTodayUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());

  const differenceInMs = isPastDate
    ? startOfTodayUTC - timeStamp * 1000
    : timeStamp * 1000 - startOfTodayUTC;

  const differenceInDays = isPastDate
    ? Math.ceil(differenceInMs / oneDay)
    : Math.floor(differenceInMs / oneDay);

  return differenceInDays;
};

export const getRelativeDay = (dateString: string): string => {
  const inputDate = new Date(dateString).getTime() / 1000;

  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const today = new Date();

  // Reset time portion to midnight (start of the day) to ignore time difference
  const todayMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
  const differenceInMs = inputDate * 1000 - todayMidnight;

  const differenceInDays = Math.floor(differenceInMs / oneDay);

  if (differenceInDays === 0) {
    return 'Today';
  } else if (differenceInDays === -1) {
    return '1 day ago';
  } else if (differenceInDays < -1) {
    return `${Math.abs(differenceInDays)} days ago`;
  }
};

export function addHttpProtocol(inputString) {
  if (!inputString.startsWith('http://') && !inputString.startsWith('https://')) {
    return 'http://' + inputString;
  }
  return inputString;
}

export const getStartAndEndTime = (interviewTimeSlot, interviewTimeSlotArray = []) => {
  let startDateTime = null;
  let endDateTime = null;
  let active = false;
  let duration = null;
  const isRescheduledTimeSlotsAvailable = interviewTimeSlotArray?.length;

  if (interviewTimeSlot && !isRescheduledTimeSlotsAvailable) {
    startDateTime = interviewTimeSlot?.start_date_time;
    endDateTime = interviewTimeSlot?.end_date_time;
    duration = interviewTimeSlot?.interview_duration;
    active = !interviewTimeSlot?.isArchived;
  } else {
    const latestTimeSlot = interviewTimeSlotArray.reduce((latest, current) => {
      return current?.isArchived === 0 &&
        (latest === null || current.interview_time_slot_id > latest.interview_time_slot_id)
        ? current
        : latest;
    }, null);

    startDateTime = latestTimeSlot?.start_date_time;
    endDateTime = latestTimeSlot?.end_date_time;
    duration = interviewTimeSlot?.interview_duration;
    active = !interviewTimeSlot?.isArchived;
  }

  return {
    startDateTime,
    endDateTime,
    duration,
    active,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const interviewTime = ({ interviewTimeSlot, interviewTimeSlotArray }: any) => {
  const { startDateTime, endDateTime } = getStartAndEndTime(
    interviewTimeSlot,
    interviewTimeSlotArray,
  );

  const start = startDateTime && convertToTimeZoneAndFormat(startDateTime, 'Etc/UTC');
  const end = endDateTime && convertToTimeZoneAndFormat(endDateTime, 'Etc/UTC');

  if (start && end) {
    const formattedDate = `${start.toFormat('EEE, dd MMM yyyy hh:mm a')} - ${end.toFormat(
      'hh:mm a',
    )} (UTC)`;

    return formattedDate;
  } else return '-';
};

export const getDueInValue = (timeStamp: number) => {
  const differenceInDays = daysDifferenceFromToday(timeStamp);
  const dueInDays = differenceInDays - 7;
  if (dueInDays === 0) return 'Due today';
  if (dueInDays > 0) return `Due in ${dueInDays} days`;
  return `Overdue (${Math.abs(dueInDays)} days)`;
};
