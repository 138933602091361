import { createApi } from '@reduxjs/toolkit/query/react';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const GetHCPocsURL = '/api/v1/internal-service/host-company/get-alternatePoc-detail';
const GetHCPocsForHcsURL = '/api/v1/internal-service/host-company/alternate-pocs';

export const GetHCPocs = createApi({
  reducerPath: 'getHCPocs',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getHCPocs: builder.query({
      query: (request) => ({ url: GetHCPocsURL, method: 'POST', body: request }),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    getHCPocsForHcs: builder.query({
      query: (request) => ({ url: GetHCPocsForHcsURL, method: 'POST', body: request }),
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const { useGetHCPocsQuery, useGetHCPocsForHcsQuery } = GetHCPocs;
