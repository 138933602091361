import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';

function getItem(label, key, icon, disabled, children) {
  return {
    title: label,
    path: key,
    icon,
    children,
    caption: disabled ? 'Permission denied' : null,
  };
}
export const navBarItems = (intl, accessPagePermission) => [
  {
    subheader: 'Overview',
    items: [getItem(intl.get('navigationBar.item.overview'), '/', <BarChartOutlinedIcon />, false)],
  },
  {
    subheader: 'Management',
    items: [
      getItem('Registration', 'registration', <AppRegistrationRoundedIcon />, false, [
        getItem('Manage Partners', '/registration/manage-partners'),
        getItem('Manage Batches', '/registration/manage-batches'),
        getItem('Manage Reports', '/registration/manage-reports'),
        getItem('Manage Intern Details', '/registration/manage-interns-detail'),
        getItem('Manage Intern Applications', '/registration/application-list'),
        getItem('Manage Intern Profiles', '/registration/manage-intern-profiles'),
        getItem('Manage Intern Videos', '/registration/manage-intern-videos'),
        getItem('Manage HC Applications', '/registration/manage-hc-applications'),
        getItem('Assign Interns to VI-er', '/registration/assign-ae'),
      ]),
      getItem('Matching', 'matching', <HandshakeRoundedIcon />, false, [
        getItem('Browse Interns', '/matching/manage-candidature'),
        getItem('Browse HCs', '/matching/host-company-details', null, false),
        getItem('Browse Internship Opportunities', '/matching/internship-requirement'),
        getItem('Recommend Interns', '/matching/manage-recommendation'),
        getItem('Manage Interviews', '/matching/manage-interviews'),
        getItem('Manage Offers', '/matching/offer-details'),
      ]),
      getItem('During Internship', 'during-internships', <WorkRoundedIcon />, false, [
        getItem('Manage Internships', '/during-internships/manage-internship'),
        getItem('Manage Intern Feedback', '/during-internships/manage-weekly-feedback-intern'),
        getItem('Manage HC Feedback', '/during-internships/manage-weekly-feedback-hc'),
      ]),
      getItem('Moo Access', 'vi-er-management', <KeyRoundedIcon />, false, [
        getItem('Manage VI Users', '/vi-er-management/manage-users'),
        getItem('Manage VI Groups', '/vi-er-management/manage-users-group'),
      ]),
      getItem('Settings', 'global-setting', <SettingsRoundedIcon />, false, [
        getItem('Login & Sign-up', '/global-setting/signup-login'),
        getItem('Platform Lists', '/global-setting/manage-reasons'),
      ]),
      accessPagePermission &&
        getItem('Support', 'support', <SupportRoundedIcon />, false, [
          getItem('External Access', '/support/access'),
          getItem('Delete User', '/support/delete-user'),
          getItem('Update User', '/support/update-user'),
        ]),
    ],
  },
];
