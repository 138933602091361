import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { removeFalsyValuesFromObject } from '../../../Util/utilities';

const reportsURL = '/api/v1/internal-service/partner/report';
const partnerURL = '/api/v1/internal-service/partner';

export const PartnerReportsApi = createApi({
  reducerPath: 'ReportsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetReportsList', 'GetColumnsList', 'GetReportData'],
  endpoints: (builder) => ({
    getAllReports: builder.query({
      query: (params) => ({
        url: `${reportsURL}/get-list`,
        method: 'GET',
        body: removeFalsyValuesFromObject(params),
      }),
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetReportsList'],
    }),
    getColumnConfigs: builder.query({
      query: (params) => ({
        url: `${reportsURL}/table-columns`,
        method: 'GET',
        body: removeFalsyValuesFromObject(params),
      }),
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetColumnsList'],
    }),
    getReportData: builder.query({
      query: ({ reportId, tabId, ...rest }) => ({
        url: `${reportsURL}/${reportId}/${tabId}/get-data`,
        method: 'GET',
        body: removeFalsyValuesFromObject(rest),
      }),
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetReportData'],
    }),
    getPrevInternshipsList: builder.query({
      query: ({ applicationId }) => ({
        url: `${partnerURL}/prev-internship-list/${applicationId}`,
        method: 'GET',
      }),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    getInterviewsList: builder.query({
      query: ({ applicationId }) => ({
        url: `${partnerURL}/interview-list/${applicationId}`,
        method: 'GET',
      }),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    getOffersList: builder.query({
      query: ({ applicationId }) => ({
        url: `${partnerURL}/offer-list/${applicationId}`,
        method: 'GET',
      }),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    createReport: builder.mutation({
      query: (payload) => {
        return { url: `${reportsURL}/create`, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetReportsList']),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    updateReport: builder.mutation({
      query: (payload) => {
        return { url: `${reportsURL}/update`, method: 'PATCH', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetReportsList', 'GetReportData']),
      onQueryStarted: onQueryStartedErrorToast,
    }),
    updateColumnConfig: builder.mutation({
      query: (payload) => {
        return { url: `${reportsURL}/update-columns`, method: 'PATCH', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetColumnsList']),
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const {
  useGetAllReportsQuery,
  useGetReportDataQuery,
  useGetPrevInternshipsListQuery,
  useGetInterviewsListQuery,
  useGetOffersListQuery,
  useGetColumnConfigsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useUpdateColumnConfigMutation,
} = PartnerReportsApi;

export default PartnerReportsApi.reducer;
