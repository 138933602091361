import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { configs } from '../../../Util/configs';

const BASE_URL = configs.REACT_APP_BASE_URL_OTHR;
const sendEmailUrl = BASE_URL + '/api/v1/internal-service/email/send/custom';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const sendEmail = createAsyncThunk('sendEmail', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(sendEmailUrl, request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const SendEmailSlice = createSlice({
  name: 'SendEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate sendEmail state handling*/
      .addCase(sendEmail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.status = action?.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.data;
      })
      .addCase(sendEmail.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSendEmailState = (state) => state.SendEmail;
export default SendEmailSlice.reducer;
