import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const assignAeURL = '/api/v1/internal-service/host-company/assign/ae';
const getAesListURL = 'api/v1/internal-service/employee/aes';

export const AssignAEApi = createApi({
  reducerPath: 'AssignAEApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAesList: builder.query({
      query: (body) => ({ url: getAesListURL, body, method: 'GET' }),
    }),
    assignAe: builder.mutation({
      query: (data) => {
        return {
          url: assignAeURL,
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

export const { useGetAesListQuery, useAssignAeMutation } = AssignAEApi;
export default AssignAEApi.reducer;
