import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { getParamObjectModifier } from '../../../Util/utility';

const getInternListURL = '/api/v1/internal-service/intern/assign/ae';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getInternListAE = createAsyncThunk(
  'getInternListAE',
  async (request, { rejectWithValue }) => {
    let tempRequest = JSON.parse(JSON.stringify(request));
    if (request?.filter?.ae_ids) {
      const index = request?.filter?.ae_ids?.findIndex((item) => parseInt(item) === 0);
      if (index !== -1) {
        tempRequest.filter.has_null_ae = 1;
        tempRequest.filter.ae_ids = tempRequest.filter.ae_ids?.filter(
          (item) => parseInt(item) !== 0,
        );
      }
    }
    const modifiedParam = getParamObjectModifier(tempRequest);

    const result = await axios
      .get(getInternListURL, { params: modifiedParam, signal: request.signal })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternListAESlice = createSlice({
  name: 'InternListAE',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getInternListAE.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternListAE.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
        state.count = action?.payload?.data?.count;
      })
      .addCase(getInternListAE.rejected, (state, action) => {
        state.status = 'Failed';
        state.error = action?.payload;
      });
  },
});

export const getInternListAEState = (state) => state.InternListAE;
export default GetInternListAESlice.reducer;
